/** Check if a response from action has errors.
 *
 * Works with response object as well as with an
 * array of response objects.
 * */
export const hasResponseErrors = response => {
  if (Array.isArray(response)) {
    return response.find(({ errors }) => errors && errors.length > 0);
  }

  return response.errors && response.errors.length > 0;
};
