<template>
  <start-page-template compact>
    <two-factor-authentication-form
      :authentication-error="formError"
      :loading="isLoading"
      @submit-code="onAuthenticate"
      @open-recovery-code-page="goToRecoveryCodePage"
    />
  </start-page-template>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex';

import StartPageTemplate from '@/components/templates/StartPageTemplate';
import TwoFactorAuthenticationForm from '@/components/start-page/TwoFactorAuthenticationForm';

import { hasResponseErrors } from '@/utils/storeActionsUtils';

import rootTypes from '@/store/types';
import { types as dashTypes } from '@/modules/dashboard/store/types';

export default {
  name: 'TwoFactorAuthenticationPage',
  components: { StartPageTemplate, TwoFactorAuthenticationForm },
  props: {
    username: {
      type: String,
      default: ''
    },
    password: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      formError: ''
    };
  },
  computed: {
    ...mapGetters({
      isLoading: rootTypes.getters.GET_LOADING
    })
  },
  mounted() {
    if (!this.username && !this.password) {
      this.$router.push({ name: 'Home' });
    }
  },
  methods: {
    ...mapMutations({
      setIsDashboardLocked: dashTypes.mutations.SET_IS_DASHBOARD_LOCKED
    }),
    ...mapActions({
      login: rootTypes.actions.LOGIN
    }),
    async onAuthenticate(code) {
      this.clearError();

      const response = await this.login({
        username: this.username,
        password: this.password,
        twoFactorAuthenticationCode: code
      });

      if (hasResponseErrors(response)) {
        this.formError = this.$t('dashboard.authentication.twoFactorAuthenticationFailed');

        return;
      }

      this.redirectToNextPage();
    },
    redirectToNextPage() {
      this.setIsDashboardLocked(false);

      if (this.pdfSummary) {
        this.$router.push({
          name: 'PatientCard',
          params: {
            id: this.pdfSummary.patientId
          }
        });

        return;
      }

      this.$router.push({ name: 'Dashboard' });
    },
    goToRecoveryCodePage() {
      this.$router.push({
        name: 'RecoveryCode',
        params: { username: this.username, password: this.password }
      });
    },
    clearError() {
      this.formError = '';
    }
  }
};
</script>
