export const PREDICATE = {
  REQUIRED: value => !!value,
  EMAIL: value => value && value.includes('@')
};

export const getValidator = (predicate, errorMessage) => value => {
  const isValid = predicate(value);

  return isValid || errorMessage;
};

export const getRequiredValidator = (errorMessage) => getValidator(PREDICATE.REQUIRED, errorMessage);

export const getEmailValidator = (errorMessage) => getValidator(PREDICATE.EMAIL, errorMessage);
